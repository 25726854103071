import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/SubscriptionCustomerRecurring';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/subscriptionCustomerRecurringService';
import * as FunctionAction from '../actions/subscriptionCustomerRecurringAction';
import {message} from "antd";
import TAG_DEFINE from "../../constants/common";
import SubscriptionCustomerRecurringRequest, {
    filtersParams
} from "../mapping/Request/SubscriptionCustomerRecurringRequest";

function* actionGetListCustomerRecurring() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_RECURRING_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters || {}));
        const result = yield call(FunctionService.getListSubscriptionCustomerRecurring, params);
        yield put(FunctionAction.subscriptionCustomerRecurringListSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_RECURRING_FAILED)))
}

function* updateAction() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_RECURRING_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SubscriptionCustomerRecurringRequest(action.params.query).exportUpdate(),
            result = yield call(FunctionService.update, {...data, id: action.params?.id});
        message.success(result);
        if(action.params?.is_listing){
            yield put(FunctionAction.subscriptionCustomerRecurringListAction(action?.params?.filters));
        }else{
            yield put(FunctionAction.subscriptionCustomerRecurringGetItemAction({id: action.params?.id}));
        }
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_RECURRING_FAILED)))
}

function* actionMaster() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.master, action.params);
        yield put(FunctionAction.subscriptionCustomerRecurringMasterSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_RECURRING_FAILED)))
}

function* actionGetItem() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_RECURRING_GET_TEM_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.getItem, action.params?.id);
        yield put(FunctionAction.subscriptionCustomerRecurringGetItemSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_RECURRING_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* subscriptionCustomerRecurringSaga() {
    yield all([
        fork(actionGetListCustomerRecurring),
        fork(updateAction),
        fork(actionGetItem),
        fork(actionMaster),
    ])
}
