import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import SubscriptionCustomerRecurringResponse, {
    MasterSubscriptionCustomerRecurringResponse
} from "../mapping/Response/SubscriptionCustomerRecurringResponse";

export function getListSubscriptionCustomerRecurring(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_RECURRING_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function master() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_RECURRING_MASTER,
        parser: data => new MasterSubscriptionCustomerRecurringResponse(data?.data?.result).exportMaster()
    }).get();
}

// export function create(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.SUBSCRIPTION_CUSTOMER_CREATE,
//     }).post(data);
// }

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_RECURRING_UPDATE,
        endpointParams: {id: encodeURIComponent(data?.id)},
        parser: data => data?.data?.result
    }).put(data);
}

export function getItem(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_RECURRING_GET_ITEM,
        endpointParams: {id: encodeURIComponent(id)},
        parser: data => new SubscriptionCustomerRecurringResponse(data.data.result).exportItem(),
    }).get();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new SubscriptionCustomerRecurringResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
