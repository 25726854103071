export default {
    DATA: {
        VIEW: {
            permission: "import_view",
            route: "/data/list",
            group: "data"
        },
        IMPORT: {
            permission: "import_import",
            route: "/data/import",
            group: "data"
        },
        TRUNCATE: {
            permission: "import_truncate",
            route: "/data/truncate",
            group: "data"
        },
    },
    HOME: {
        VIEW: {
            permission: "dashboard",
            route: "/",
            group: "home"
        },
    },
    USER: {
        VIEW: {
            permission: "list",
            route: "/users/list",
            group: "user"
        },
        CREATE: {
            permission: "create",
            route: "/users/action",
            group: "user"
        },
        UPDATE: {
            permission: "update",
            withParams: true,
            route: "/users/action/:id",
            group: "user"
        },
    },
    CUSTOMER: {
        VIEW: {
            permission: "list",
            route: "/customers/list",
            group: "customer"
        },
        CREATE: {
            permission: "update",
            route: "/customers/action",
            group: "customer"
        },
        UPDATE: {
            permission: "update",
            withParams: true,
            route: "/customers/action/:id",
            group: "customer"
        },
        TICKET_LIST: {
            permission: "ticket_list",
            route: "/tickets/list",
            group: "customer"
        },
        TICKET_UPDATE: {
            permission: "ticket_update",
            withParams: true,
            route: "/tickets/action/:id",
            group: "customer"
        },
        TEAM_POINT: {
            permission: "team_point",
            withParams: true,
            route: "/team-point/list",
            group: "customer"
        },
    },
    SUBSCRIPTION: {
        PACKAGE_VIEW: {
            permission: "package_list",
            route: "/subscription/package/list",
            group: "subscription"
        },
        PACKAGE_UPDATE: {
            permission: "package_update",
            route: "/subscription/package/action/:id",
            group: "subscription"
        },
        CUSTOMER_VIEW: {
            permission: "customer_list",
            route: "/subscription/customer/list",
            group: "subscription"
        },
        CUSTOMER_UPDATE: {
            permission: "customer_update",
            route: "/subscription/customer/action/:id",
            group: "subscription"
        },
        TRANSACTION_VIEW: {
            permission: "transaction_list",
            route: "/subscription/transaction/list",
            group: "subscription"
        },
        CANCELATION_FEEDBACK_VIEW: {
            permission: "cancelation_feedback_view",
            route: "/subscription/cancelation-view/list",
            group: "subscription"
        },
        GENERAL_PACKAGE_VIEW: {
            permission: "general_package_view",
            route: "/subscription/general-package/list",
            group: "subscription"
        },
        GEO_DATA: {
            permission: "geo_data_view",
            route: "/subscription/geo-data/list",
            group: "subscription"
        },
        CUSTOM_PACKAGE: {
            permission: "custom_package_view",
            route: "/subscription/custom-package/list",
            group: "subscription"
        },
        CUSTOMER_RECURRING: {
            permission: "recurring_view",
            route: "/subscription/customer-recurring/list",
            group: "subscription"
        },
        CUSTOMER_RECURRING_UPDATE: {
            permission: "recurring_view",
            route: "/subscription/customer-recurring/:id",
            group: "subscription"
        }
    },
    EXCLUSIVE_CONTENT: {
        VIEW: {
            permission: "post_list",
            route: "/exclusive-content/list",
            group: "exclusive_content"
        },
        GET: {
            permission: "post_detail",
            route: "/exclusive-content/:id",
            group: "exclusive_content"
        },
        CREATE: {
            permission: "post_create",
            route: "/exclusive-content/action",
            group: "exclusive_content"
        },
        UPDATE: {
            permission: "post_update",
            withParams: true,
            route: "/exclusive-content/action/:id",
            group: "exclusive_content"
        },
        LAYOUT_LIST: {
            permission: "layout_view",
            route: "/exclusive-content-layout/list",
            group: "exclusive_content"
        },
        LAYOUT_UPDATE: {
            permission: "layout_update",
            withParams: true,
            route: "/exclusive-content-layout/action/:id",
            group: "exclusive_content"
        },
    },
    COMPANY: {
        VIEW: {
            permission: "list",
            route: "/company/list",
            group: "company"
        },
        CREATE: {
            permission: "update",
            route: "/company/action",
            group: "company"
        },
        UPDATE: {
            permission: "update",
            withParams: true,
            route: "/company/action/:id",
            group: "company"
        }
    },
    CONFIGURATION: {
        VIEW: {
            permission: "list",
            route: "/configuration/list",
            group: "configuration"
        },
        CREATE: {
            permission: "update",
            route: "/configuration/action",
            group: "configuration"
        },
        UPDATE: {
            permission: "update",
            withParams: true,
            route: "/configuration/action/:id",
            group: "configuration"
        }
    },
}
